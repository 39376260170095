import React from "react";

const Home = React.lazy(() => import("../pages/Home/Home"));
const Bible = React.lazy(() => import("../pages/Bible//Bible"));
const Books = React.lazy(() => import("../pages/Books/Books"));
const New = React.lazy(() => import("../pages/New/New"));
const About = React.lazy(() => import("../pages/About/About"));
const BlogMain = React.lazy(() => import("../pages/Blog/BlogMain"));
const DetailPoster = React.lazy(() => import("../pages/Blog//DetailPoster"));
const MorePostsPage = React.lazy(() => import("../pages/Blog/MorePostsPage"));
const NotFound = React.lazy(() => import("../components/NotFound"));
const Admin = React.lazy(() => import("../pages/Admin/AdminPage"));
const AdminLogin = React.lazy(() => import("../pages/Admin/AdminLogin"));
const BlogManager = React.lazy(() => import("../pages/Admin/BlogManager"));
const ResourceManager = React.lazy(
  () => import("../pages/Admin/ResourceManager")
);
const UserManager = React.lazy(() => import("../pages/Admin/UserManager"));
const AdminPosts = React.lazy(() => import("../pages/Admin/AdminPosts"));
const Give = React.lazy(() => import("../pages/Home/Give"));
const Apply = React.lazy(() => import("../pages/Home/Apply"));
export {
  Home,
  Bible,
  Books,
  New,
  About,
  BlogMain,
  DetailPoster,
  MorePostsPage,
  NotFound,
  Admin,
  AdminLogin,
  BlogManager,
  ResourceManager,
  UserManager,
  AdminPosts,
  Give,
  Apply,
};
