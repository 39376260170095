import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useSWR from "swr";
import Loading from "./components/Loading";
import {
  Home,
  Bible,
  Books,
  New,
  About,
  BlogMain,
  DetailPoster,
  MorePostsPage,
  NotFound,
  AdminLogin,
  BlogManager,
  ResourceManager,
  UserManager,
  AdminPosts,
  Give,
  Apply,
} from "./modules/components";

import { postsAction } from "./redux/reducer/pages/Blog/postsSlice";

function App() {
  const dispatch = useDispatch();
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);
  // console.log("environment:", `${process.env.NODE_ENV}`);
  const {
    data: blogPosts,
    error: blogPostsError,
    isLoading: blogLoading,
    mutate,
  } = useSWR(
    `${process.env.REACT_APP_API_URL}/blog-search.php?date=&category=&writer=&search=`,
    fetcher
  );

  useEffect(() => {
    mutate();

    if (blogPosts && blogPosts["body"]) {
      dispatch(postsAction({ data: blogPosts.body }));
    }
  }, [blogPosts]);

  if (blogLoading) {
    return <Loading />;
  }
  if (blogPostsError) {
    return <div>서버에러! 관리자에게 문의해 주세요</div>;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/new/" element={<New />} />
          <Route path="/blog/post/:postId/" element={<DetailPoster />} />
          <Route path="/blog/:categoryName/" element={<BlogMain />} />
          <Route path="/blog/" element={<BlogMain />} />
          <Route path="/admin/blog/" element={<BlogManager />} />
          <Route path="/admin/resource/" element={<ResourceManager />} />
          <Route path="/admin/user/" element={<UserManager />} />
          <Route path="/give/" element={<Give />} />
          <Route path="/apply/" element={<Apply />} />
          <Route path="/free-bible/" element={<Bible />} />
          <Route path="/books/" element={<Books />} />
          <Route path="/about/" element={<About />} />
          <Route path="/page/" element={<MorePostsPage />} />
          <Route path="/admin/login/" element={<AdminLogin />} />
          <Route path="/admin/blog/write-posting/" element={<AdminPosts />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
