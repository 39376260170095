import React from "react";
import FadeLoader from "react-spinners/FadeLoader";
import styles from "../styles/components/Loading.module.scss";
const Loading = () => {
  return (
    <div className={styles.contentWrap}>
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <FadeLoader
          color="#c4a006"
          cssOverride={{}}
          height={20}
          loading
          radius={4}
          speedMultiplier={1}
          width={5}
        />
      </div>
    </div>
  );
};

export default Loading;
