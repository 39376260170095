import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postsProps } from "../../../../modules/dummyData";

interface postsSlicesProps {
  posts: postsProps[];
  modifyIdx: number;
}
const initialState: postsSlicesProps = {
  posts: [],
  modifyIdx: -1,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    postsAction: (state, action: PayloadAction<any>) => {
      state.posts = action.payload.data;
    },
    modifyAction: (state, action: PayloadAction<any>) => {
      state.modifyIdx = action.payload.idx;
    },
  },
});

export const { postsAction, modifyAction } = loginSlice.actions;
export default loginSlice.reducer;
