import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dummyLogin } from "../../../modules/dummyData";

interface loginProps {
  id: string;
  pw: string;
  auth: number;
}

const initialState: loginProps = { id: "admin", pw: "admin", auth: 1 };

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginAction: (state, action: PayloadAction<loginProps>) => {
      state.id = action.payload.id;
      state.pw = action.payload.pw;
      state.auth = 1;
      
    },
  },
});

export const { loginAction } = loginSlice.actions;
export default loginSlice.reducer;
